import { Link, NavLink } from 'react-router-dom'
import { confirm } from '../../reusable/Confirmation';
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { isThisAdmin } from '../../reusable/PermissoinUtils'

 
const Navbar = ({history, toggleMenu, setToggleMenu}) => {

    const [ user, setUser ] = useState({});

    useEffect(() => {
        if (localStorage.getItem('accessToken', '')){
            axios.get("auth/profile/").then((response) => {
                setUser(response.data);
            }).catch((error) => {
                //
            });
        }
    }, []);

    const onPaths = (paths) => {
        return (match, location) => {
          return paths.includes(location.pathname);
        };
    };

    const logout = async () => {
        const result = await confirm('Are you sure to logout?');
        if(!result) return;

        history('/logout');
    };

    const showUserName = () => {
        let userName = user.first_name + ' ' + user.last_name;
        return (userName.length > 20) ? userName.substring(0,17) + '...' : userName;
    };

    return (
        <ul className={"navbar-nav menubar collapse navbar-collapse " + toggleMenu} id="navbarCollapse" >
                {localStorage.getItem('accessToken', '') ? (
                    <>
                    <li className="nav-item"><NavLink onClick={ () => setToggleMenu('') } className="nav-link" activeClassName="active" to="/" exact={true}>Dashboard</NavLink></li>
                    <li className="nav-item"><NavLink onClick={ () => setToggleMenu('') } className="nav-link" activeClassName="active" to="/erfq-summary">E-RFQ</NavLink></li>
                    <li className="nav-item"><NavLink onClick={ () => setToggleMenu('') } className="nav-link" activeClassName="active" to="/equote-summary">E-Quote</NavLink></li>
                    <li className="nav-item"><NavLink onClick={ () => setToggleMenu('') } className="nav-link" activeClassName="active" to="/po-summary">POM</NavLink></li>
                    <li className="nav-item"><NavLink onClick={ () => setToggleMenu('') } className="nav-link" activeClassName="active" to="/po-seller-summary">OMS</NavLink></li>
                    <li className="nav-item"><NavLink onClick={ () => setToggleMenu('') } className="nav-link" activeClassName="active" to="/ems-summary">EMS</NavLink></li>
                    <li className="nav-item"><NavLink onClick={ () => setToggleMenu('') } className="nav-link" activeClassName="active" to="/myconnect" 
                        isActive={onPaths(["/myconnect", "/pendinglist", "/requestlist", "/connsearch"])}
                    >My Connect</NavLink></li>
                    <li className="nav-item"><NavLink onClick={ () => setToggleMenu('') } className="nav-link" activeClassName="active" to="/invite">Invite Company</NavLink></li>
                    <li className="nav-item profile-link">
                        Welcome { showUserName() } <span>({isThisAdmin() ? 'ADMIN' : 'EMPLOYEE'})</span>
                        <div className="profile-icon" data-bs-toggle="dropdown" aria-expanded="false"><img src="/assets/images/profile-icon.png" alt="Profile" /></div>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li><NavLink onClick={ () => { setToggleMenu(''); } } to="/profile">My Profile</NavLink></li>
                            <li><NavLink onClick={ () => { setToggleMenu(''); } } to="/address-book">Address Book</NavLink></li>
                            <li><a className='cursor-pointer' onClick={ () => { setToggleMenu(''); logout(); }  }>Logout</a></li>
                        </ul>
                    </li>
                    </>
                ) : (
                    <>
                    <li className="nav-item"><NavLink onClick={ () => setToggleMenu('') } to="/" activeClassName="active" className="nav-link" exact={true}>Home</NavLink></li>
                    <li className="nav-item"><NavLink onClick={ () => setToggleMenu('') } to="/about-us" activeClassName="active" className="nav-link">About Us</NavLink></li>
                    <li className="nav-item"><NavLink onClick={ () => setToggleMenu('') } to="/services" activeClassName="active" className="nav-link">Services</NavLink></li>
                    <li className="nav-item"><NavLink onClick={ () => setToggleMenu('') } to="/faq" activeClassName="active" className="nav-link">FAQ</NavLink></li>
                    <li className="nav-item"><NavLink onClick={ () => setToggleMenu('') } to="/contactus"  activeClassName="active" className="nav-link">Contact US</NavLink></li>
                    <li className="nav-item"><NavLink onClick={ () => setToggleMenu('') } to="/login" activeClassName="active" className="nav-link login-link">Login</NavLink></li>
                    </>
                )}
        </ul>
    )

}

export default Navbar